import React from 'react';

const AnalyticsScript = function() {
  React.useEffect(function() {
    // Select the containing iframe
    async function yeet() {
      let frame = document.getElementById('script-frame');
      let doc = frame.document;
      if (frame.contentWindow) doc = frame.contentWindow.document;

      // Fetch the static element
      let stat = await fetch('/gstatic.html.x');
      stat.text().then(function(value) {
        doc.open();
        doc.writeln(value);
        doc.close();
      });
    }
    yeet();
  });

  return (
    <iframe
      title="kym-script"
      frameBorder="0"
      width="0"
      height="0"
      id="script-frame"
      src="about:blank"></iframe>
  );
};

export default AnalyticsScript;
