import React from 'react';

import Logo from 'src/components/global/Logo';

const Divider = () => (
  <div className="divider">
    <Logo />
  </div>
);

export default Divider;
