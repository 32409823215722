import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import Divider from 'src/components/Divider';
import Chevron from 'src/assets/svgs/chevron-down.svg';
import AnalyticsScript from 'src/components/Analytics';
import * as React from 'react';
export default {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  Divider,
  Chevron,
  AnalyticsScript,
  React
};