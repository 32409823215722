exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-issue-js": () => import("./../../../src/pages/instagram-issue.js" /* webpackChunkName: "component---src-pages-instagram-issue-js" */),
  "component---src-pages-instagram-js": () => import("./../../../src/pages/instagram.js" /* webpackChunkName: "component---src-pages-instagram-js" */),
  "component---src-pages-mdx-about-mdx": () => import("./../../../src/pages-mdx/about.mdx" /* webpackChunkName: "component---src-pages-mdx-about-mdx" */),
  "component---src-pages-mdx-contact-mdx": () => import("./../../../src/pages-mdx/contact.mdx" /* webpackChunkName: "component---src-pages-mdx-contact-mdx" */),
  "component---src-pages-mdx-faq-mdx": () => import("./../../../src/pages-mdx/faq.mdx" /* webpackChunkName: "component---src-pages-mdx-faq-mdx" */),
  "component---src-pages-mdx-positions-mdx": () => import("./../../../src/pages-mdx/positions.mdx" /* webpackChunkName: "component---src-pages-mdx-positions-mdx" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-releases-index-js": () => import("./../../../src/pages/releases/index.js" /* webpackChunkName: "component---src-pages-releases-index-js" */),
  "component---src-pages-return-js": () => import("./../../../src/pages/return.js" /* webpackChunkName: "component---src-pages-return-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */),
  "component---src-templates-release-js": () => import("./../../../src/templates/Release.js" /* webpackChunkName: "component---src-templates-release-js" */)
}

