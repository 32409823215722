import React from 'react';

import classNames from 'classnames';

export default ({ className, ...props }) => (
  <svg
    {...props}
    className={classNames('logo', { [className]: className })}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 370 329.9">
    <path d="M343.6 7.8v314H370v7.8H238.6v-7.8H265V7.8h-26.4V0H370v7.8zM231.6 121.5l-74.9 208.4h-14L34.5 19.8v277q0 12.7 8.2 19a29 29 0 0 0 18 6.3v7.8H0V322a28.6 28.6 0 0 0 18.2-6.3q8-6.3 8-19V10.2H0V2.3h104.3L184 222.1 231.8 90" />
  </svg>
);
